import React from 'react'
import { Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

const Continue = tw.button`font-display text-sm border-2 py-1 border-dolphin-gray hover:border-dolphin-gray bg-dolphin-gray hover:bg-white hover:text-dolphin-gray text-white px-2 rounded uppercase`
const PageNav = styled.div`
	${tw`flex flex-row items-center justify-center -mt-16 md:mt-16 mb-8`}

	> a {
		${tw`mx-2`}
	}

	> a.active button {
		${tw`bg-white text-dolphin-gray`}
	}
`
const PrevNext = styled(Continue)``

const Pagination = ({
	currentPage,
	pageLength,
	slug,
	pageArray,
	checkedLocale,
}) => {
	const prev = currentPage - 1 === 1 ? '' : (currentPage - 1 + '/')
	const next = currentPage + 1 === 1 ? '/' : (currentPage + 1 + '/')
	return (
		<PageNav>
			{currentPage === 1 ? (
				false
			) : (
				<Link
					to={`${checkedLocale}/blog/${slug}/${currentPage === 1 ? '' : prev}`}
					disabled
				>
					<PrevNext>Previous</PrevNext>
				</Link>
			)}
			{pageArray.map((_, index) => {
				return (
					<Link
						className={index + 1 === currentPage ? 'active' : ''}
						key={index}
						to={`${checkedLocale}/blog/${slug}/${index === 0 ? '' : (index + 1 + '/')}`}
					>
						<Continue>{index + 1}</Continue>
					</Link>
				)
			})}
			{currentPage === pageLength ? (
				false
			) : (
				<Link
					to={`${checkedLocale}/blog/${slug}/${next}`}
				>
					<PrevNext>Next</PrevNext>
				</Link>
			)}
		</PageNav>
	)
}

export default Pagination
